import React from 'react';

const RecentTransactions = ({ recentTransactions }) => {
    // const transactions = [
    //     {
    //         id: "TXN1234567890",
    //         date: new Date('2024-11-26T10:30:00Z').toISOString(),
    //         amount: 49.99,
    //         status: "Completed",
    //         statusColor: "bg-green-100 text-green-800"
    //     },
    //     {
    //         id: "TXN0987654321",
    //         date: new Date('2024-11-25T15:00:00Z').toISOString(),
    //         amount: 129.99,
    //         status: "Pending",
    //         statusColor: "bg-yellow-100 text-yellow-800"
    //     },
    //     {
    //         id: "TXN1122334455",
    //         date: new Date('2024-11-24T18:45:00Z').toISOString(),
    //         amount: 9.99,
    //         status: "Failed",
    //         statusColor: "bg-red-100 text-red-800"
    //     }];

    return (
        <div className="mx-auto p-6 bg-white shadow rounded-lg mt-10">
            <h2 className="text-2xl font-semibold mb-4">Recent Transactions</h2>
            {recentTransactions && recentTransactions.length > 0 ? (
                <div className="flex flex-col gap-4">
                    {recentTransactions.map((transaction, index) => (
                        <div key={index} className="p-4 border rounded-lg shadow-sm flex justify-between items-center">
                            <div className="flex flex-col md:flex-row md:gap-8">
                                {/* <div className="text-sm">
                                    <span className="font-semibold">Transaction ID:</span> {transaction.id}
                                </div> */}
                                <div className="text-sm">
                                    <span className="font-semibold">Date:</span> {new Date(transaction.created * 1000).toLocaleString()}
                                </div>
                                <div className="text-sm">
                                    <span className="font-semibold">Amount: </span>
                                    <span className={`font-semibold ${transaction.amount > 0 ? 'bg-green-100' : 'bg-red-100'}`}>${(transaction.amount/ 100).toFixed(2)}</span>
                                </div>
                                <div className="text-sm">
                                    <span className="font-semibold">Type:</span>
                                    <span className={`ml-2 px-3 py-1 rounded-full text-xs font-semibold bg-blue-100`}>
                                        {transaction.type}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center text-gray-500">No transactions found.</div>
            )}
        </div>
    );
}

export default RecentTransactions;

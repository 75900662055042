import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import AuthContext from 'context/AuthContext'
import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const navigation = [
    { name: 'Product', href: '/seller/manage-products' },
    { name: 'Order', href: '/seller/manage-orders' },
]
const userNavigation = [
    // { name: 'Sign out', href: '/login' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Header = (props) => {

    const { root = false } = props;

    const navigate = useNavigate()
    const { user, logout } = useContext(AuthContext)

    const handleLogout = () => {
        logout();
        navigate('/login')
    }

    return <Disclosure as="nav" className="bg-gray-800">
        <div className="mx-auto max-w-10xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                    <div className="md:block">
                        <div className="ml-0 flex items-baseline space-x-4">
                            {(root === false) && <Link
                                to={-1}
                                className={classNames(
                                    'bg-gray-900 text-white',
                                    'rounded-md px-3 py-2 text-sm font-medium',
                                )}
                            >
                                Back
                            </Link>}
                            {/* {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    aria-current={window.location.pathname === item.href ? 'page' : undefined}
                                    className={classNames(
                                        window.location.pathname === item.href ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'rounded-md px-3 py-2 text-sm font-medium',
                                    )}
                                >
                                    {item.name}
                                </Link>
                            ))} */}
                        </div>
                    </div>
                </div>
                <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                        <h1 className='text-sm text-white'>Hi, {user?.email ?? 'Seller'}</h1>
                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                            <div>
                                <MenuButton className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">Open user menu</span>
                                    <img alt="" src={user?.imageUrl ?? 'https://www.gravatar.com/avatar'} className="size-8 rounded-full" />
                                </MenuButton>
                            </div>
                            <MenuItems
                                transition
                                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                {userNavigation.map((item) => (
                                    <MenuItem key={item.name}>
                                        <a
                                            href={item.href}
                                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none"
                                        >
                                            {item.name}
                                        </a>
                                    </MenuItem>
                                ))}

                                <MenuItem key='logout'>
                                    <div
                                        onClick={handleLogout}
                                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none"
                                    >
                                        Sign out
                                    </div>
                                </MenuItem>
                            </MenuItems>
                        </Menu>
                    </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open main menu</span>
                        {/* <Bars3Icon aria-hidden="true" className="block size-6 group-data-[open]:hidden" /> */}
                        {/* <XMarkIcon aria-hidden="true" className="hidden size-6 group-data-[open]:block" /> */}
                    </DisclosureButton>
                </div>
            </div>
        </div>

        <DisclosurePanel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                    <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        aria-current={item ? 'page' : undefined}
                        className={classNames(
                            item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'block rounded-md px-3 py-2 text-base font-medium',
                        )}
                    >
                        {item.name}
                    </DisclosureButton>
                ))}

            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                    <div className="shrink-0">
                        <img alt="" src={user?.imageUrl ?? 'https://www.gravatar.com/avatar'} className="size-10 rounded-full" />
                    </div>
                    <div className="ml-3">
                        <div className="text-base/5 font-medium text-white">{user.name}</div>
                        <div className="text-sm font-medium text-gray-400">{user.email}</div>
                    </div>
                    <button
                        type="button"
                        className="relative ml-auto shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                    </button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                        <DisclosureButton
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                            {item.name}
                        </DisclosureButton>
                    ))}

                </div>
            </div>
        </DisclosurePanel>
    </Disclosure>

}

export default Header
import { getAuth } from 'firebase/auth';
import React, { createContext, useState, useEffect } from 'react';
import { app } from 'services/firebase';

const AuthContext = createContext();
const auth = getAuth(app);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [isAuthenticated, setIsAuthenticated] = useState(JSON.parse(localStorage.getItem('user')) !== null);
  const [selectedStore, setSelectedStore] = useState(JSON.parse(localStorage.getItem('selectedStore')));
  const [listStore, setListStore] = useState(JSON.parse(localStorage.getItem('listStore')));

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const storedSelectedStore = JSON.parse(localStorage.getItem('selectedStore'));
    const storedListStore = JSON.parse(localStorage.getItem('listStore'));
    if (storedUser) {
      setUser(storedUser);
      setIsAuthenticated(true);
    }
    if (storedListStore) {
      setListStore(Array.isArray(storedListStore) ? storedListStore : [])
    }
    if (storedSelectedStore) {
      setSelectedStore(storedSelectedStore)
    } else if (listStore) {
      setSelectedStore(listStore[0])

    }
  }, []);

  const login = (userData) => {
    setUser(userData);
    setIsAuthenticated(true);
    localStorage.setItem('user', JSON.stringify(userData));
    return true; //TODO mockup login success
  };

  const logout = async () => {
    await auth.signOut();
    setUser(null);
    setIsAuthenticated(false);
    setSelectedStore(null);
    localStorage.removeItem('user');
    localStorage.removeItem('selectedStore');
    localStorage.removeItem('listStore');
  };

  const doSelectStore = (store) => {
    if (store) {
      setSelectedStore(store)
      localStorage.setItem('selectedStore', JSON.stringify(store));
    }
  }

  const saveListStore = (stores) => {
    if (stores) {
      localStorage.setItem('listStore', JSON.stringify(stores))
      setListStore(stores)
    }
  }
  const findStoreTitleByAddress = (storeAddress) => {

    if (!Array.isArray(listStore)) {
      return "Store list is not available";
    }
    const store = listStore.find((store) => store.address === storeAddress);
    return store ? store.title : storeAddress;
  };

  return (
    <AuthContext.Provider value={{
      user, login, logout, isAuthenticated,
      selectedStore, doSelectStore, listStore, saveListStore, findStoreTitleByAddress
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import React from 'react';
import { useNavigate } from 'react-router';

const RecentOrders = ({ recentOrders }) => {

    const navigate = useNavigate()

    return (
        <div className="p-6 bg-white shadow rounded-lg mt-5">
            <h2 className="text-2xl font-semibold mb-4">Recent Orders
                {/* <span className="text-gray-400"> */}
                {/* <i className="fas fa-info-circle"></i></span> */}
            </h2>
            {recentOrders && recentOrders.length > 0 ? (<div className="flex flex-col gap-2">
                {recentOrders?.map((order, index) => (
                    <div key={index} className="p-4 border rounded-lg shadow-sm flex justify-between items-center">
                        <div className="flex flex-col md:flex-row md:gap-8">
                            {/* <div className="text-sm">
                                <span className="font-semibold">Order ID:</span> {order.id}
                            </div> */}
                            <div className="text-sm">
                                <span className="font-semibold">Date:</span>  {order.createdAt ? new Date(order.createdAt).toLocaleString() : ''}
                            </div>
                            <div className="text-sm">
                                <span className="font-semibold">Price:</span> ${(order.cart.totalAmount).toFixed(2)}
                            </div>
                            <div className="text-sm">
                                <span className="font-semibold">Status:</span>
                                <span className={`ml-2 px-3 py-1 rounded-full text-xs font-semibold ${order.orderStatus === 'COMPLETED' ? 'bg-green-100' : 'bg-yellow-100'}`}>
                                    {order.orderStatus}
                                </span>
                            </div>
                        </div>
                        <div>
                            <button
                                onClick={() => { navigate(`/admin/order/${order.id}`) }}
                                className="text-gray-700 font-semibold border border-gray-300 px-4 py-2 rounded-md focus:outline-none">
                                View Detail
                            </button>
                        </div>
                    </div>
                ))}
                {/* <button className="mt-4 w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md">
                    <i className="fas fa-eye mr-2"></i>
                    See all orders
                </button> */}
            </div>) : (
                <div className="text-center text-gray-500">No orders found.</div>
            )}
        </div>
    );
}

export default RecentOrders;

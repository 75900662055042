import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ImageGalleryDialog = ({ images }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const openDialog = (images) => {
    setSelectedImages(images);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedImages([]);
  };


  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <>
      {images.length === 0 ? <div className='italic text-gray-400 text-s'>No image</div> :
        <div
          className='text-blue-700 underline' onClick={() => openDialog(images)}>View {images.length} Images</div>}
      {/* Dialog Modal */}
      {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-lg w-full">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeDialog}
            >
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-6">
              {selectedImages.map((img, index) => (
                <div key={index}
                  onClick={() => openFullscreen(img)}
                  className="flex justify-center">
                  <img
                    src={img}
                    alt={`full-img-${index}`}
                    className="w-full h-200 object-cover rounded-md shadow-sm"
                  />
                </div>
              ))}
            </div>
          </div>

          {fullscreenImage && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80">
              <div className="relative">
                <button
                  className="absolute top-4 right-4 text-white hover:text-gray-400"
                  onClick={closeFullscreen}
                >
                  <FontAwesomeIcon icon={faTimes} size="2x" />
                </button>
                <img
                  src={fullscreenImage}
                  alt="fullscreen"
                  className="max-w-full max-h-screen object-contain"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ImageGalleryDialog;
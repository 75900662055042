import React from 'react';

const Pagination = ({ currentPage, onPageChange, isNextPageAvailable }) => {
    if (currentPage === 1 && !isNextPageAvailable) return null; // No need to display pagination if there is only one page and no more pages available

    return (
        <div className="flex justify-center items-center mt-4">
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-1 border rounded-md mx-1 bg-white text-gray-700 disabled:opacity-50"
            >
                Previous
            </button>

            <span className="px-3">{`Page ${currentPage}`}</span>

            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={!isNextPageAvailable}
                className="px-3 py-1 border rounded-md mx-1 bg-white text-gray-700 disabled:opacity-50"
            >
                Next
            </button>
        </div>
    );
};

export default Pagination;
import { getFirestore, collection, getDocs, orderBy, limit, query, where, getDoc, doc, startAfter, startAt, onSnapshot, Timestamp, updateDoc, addDoc } from 'firebase/firestore';
import { app } from '../services/firebase';
import { isEmail } from 'utils/utils';

const db = getFirestore(app);


export const fetchSuperAdmin = async (uid) => {
    const publicManager = doc(db, 'public', 'managers')
    const docSnap = await getDoc(publicManager);
    if (docSnap.exists()) {
        return docSnap.data().super.includes(uid)
    } else {
        return false;
    }
}

export const fetchRemoteStores = async (user) => {
    const storesCollection = collection(db, 'stores');
    const storesSnapshot = await getDocs(storesCollection);
    const storesList = storesSnapshot.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });

    if (user.isSuper) {
        return storesList
    }

    //check privilege
    const publicManager = doc(db, 'public', 'managers')
    const currentEmail = user.email;
    try {
        const docSnap = await getDoc(publicManager);
        if (docSnap.exists()) {
            const stores = docSnap.data().stores
            const allowedStoreIds = getAllowedStore(currentEmail, stores);
            const allowedStores = storesList.filter(store => allowedStoreIds.includes(store.id))
            return allowedStores
        } else {
            return null;
        }
    } catch (err) {
        return null
    }
}

const getAllowedStore = (email, stores) => {
    const allowedStores = []
    console.log(stores)
    for (const key in stores) {
        if (stores.hasOwnProperty(key)) {
            if (stores[key].includes(email)) {
                allowedStores.push(key)
            }
        }
    }
    return allowedStores
}


export const subscribeOrderByStore = (selectedStoreAddress, rowsPerPage, onOrdersChange) => {
    const ordersCollection = collection(db, 'orders');
    const ordersQuery = query(
        ordersCollection,
        where('cart.shopAddress', '==', selectedStoreAddress),
        orderBy('createdAt', 'desc'),
        limit(rowsPerPage)
    );

    // Use onSnapshot to subscribe to real-time updates
    const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
        const ordersList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        // Pass the updated ordersList to the callback
        onOrdersChange(ordersList);
    });

    // Return the unsubscribe function to allow for cleanup
    return unsubscribe;
};

export const subscribeOrderAllStore = (rowsPerPage, onOrdersChange) => {
    const ordersCollection = collection(db, 'orders');
    const ordersQuery = query(
        ordersCollection,
        orderBy('createdAt', 'desc'),
        limit(rowsPerPage)
    );

    // Use onSnapshot to subscribe to real-time updates
    const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
        const ordersList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        // Pass the updated ordersList to the callback
        onOrdersChange(ordersList);
    });

    // Return the unsubscribe function to allow for cleanup
    return unsubscribe;
};

export const updateOrderStatus = async (order, newStatus) => {
    try {
        const orderId = order.id
        const orderRef = doc(db, 'orders', orderId);
        await updateDoc(orderRef, {
            orderStatus: newStatus,
            updatedAt: Timestamp.now()
        });


        if (newStatus === 'COMPLETED' && orderId != null && await checkBunnyTransactionOrder(orderId)) {
            //create bunnies transaction
            if (order.cart.totalAmount >= 5) {
                const transactionBunnies = {
                    amount: Math.floor(order.cart.totalAmount * 10),
                    uid: order.customerModel.uid,
                    type: 'ORDER',
                    status: 'RETURNED',
                    created_at: Timestamp.now(),
                    sourceDishId: null,
                    sourceOrderId: orderId,
                    sourceRatingId: null,
                    sourceReferralId: null,
                    updated_at: Timestamp.now()
                }
                //approved
                console.log('approved: ', transactionBunnies)
                const newTransactionId =
                    await saveBunnyTransaction(transactionBunnies);
                await saveTransactionBunniesReference(transactionBunnies.amount,
                    newTransactionId, order.customerModel.uid);
            }
        }
        console.log(`Order ${orderId} status updated to ${newStatus}`);
    } catch (error) {
        console.error("Error updating order status:", error);
    }
}

const saveTransactionBunniesReference = async (bunnyTransactionAmount, newTransactionId, uid) => {
    try {
        // Get the user document reference
        const userRef = doc(db, 'users', uid);
        const userSnapshot = await getDoc(userRef);

        if (!userSnapshot.exists()) {
            throw new Error(`User with uid ${uid} does not exist`);
        }

        // Retrieve user data and update fields
        const userModel = userSnapshot.data();

        // Extract current bunnies balance and bunny transactions
        const currentBunniesBalance = userModel.bunnies || 0;
        const bunnyTransactions = userModel.bunniesTransactions || [];

        // Update the user document
        await updateDoc(userRef, {
            bunnies: currentBunniesBalance + bunnyTransactionAmount,
            bunniesTransactions: [...bunnyTransactions, newTransactionId], // Add new transaction ID to the list
            updated_at: Timestamp.now() // Use the current date and time
        });

        console.log('Transaction reference saved successfully.');
    } catch (error) {
        console.error("Error saving transaction reference:", error);
    }
};

const saveBunnyTransaction = async (request) => {
    try {
        // Add the request data to the "bunnies" collection
        const bunniesRef = collection(db, 'bunnies');
        const ref = await addDoc(bunniesRef, request);

        // Update the document with its generated ID
        await updateDoc(ref, { id: ref.id });

        // Return the document ID
        return ref.id;
    } catch (error) {
        console.error("Error saving bunny transaction:", error);
        throw error;
    }
};

const checkBunnyTransactionOrder = async (orderId) => {
    const cl = collection(db, 'bunnies')
    const q = query(cl, where('sourceOrderId', '==', orderId))
    const bunnyTransaction = await getDocs(q)
    return bunnyTransaction.empty;
}

// export const fetchOrderByStore = async (selectedStoreAddress) => {
//     const ordersCollection = collection(db, 'orders');
//     const q = query(ordersCollection,
//         where("cart.shopAddress", "==", selectedStoreAddress),
//         orderBy('createdAt', 'desc'),
//         limit(50)
//     );

//     const ordersSnapshot = await getDocs(q);
//     const ordersList = ordersSnapshot.docs.map(doc => {
//         return {
//             ...doc.data(),
//             id: doc.id
//         }
//     });
//     console.log('orderList = ', ordersList)
//     return ordersList;
// }

// export const fixTransactionUser = async (userId) => {
//     const userRef = doc(db, 'users', userId);
//     await updateDoc(userRef, {
//         transactions: [],
//         updatedAt: Timestamp.now()
//     });
// }

export const fetchUsers = async (currentPage, lastVisibleStack, usersPerPage, searchInput, sortColumn, sortOrder) => {
    const cl = collection(db, 'users');
    let mQuery;
    if (currentPage === 1) {
        // Fetch the first page
        mQuery = query(cl, orderBy(sortColumn, sortOrder), limit(usersPerPage));
    } else if (currentPage > lastVisibleStack.length) {
        // Fetch the next page after the last visible document of the previous page
        const lastVisible = lastVisibleStack[lastVisibleStack.length - 1];
        mQuery = query(cl, orderBy(sortColumn, sortOrder), startAfter(lastVisible), limit(usersPerPage));
    } else {
        // Fetch the previous page using the document at the start of the previous page
        const prevLastVisible = lastVisibleStack[currentPage - 2];
        mQuery = query(cl, orderBy(sortColumn, sortOrder), startAt(prevLastVisible), limit(usersPerPage));
    }

    const searchField = isEmail(searchInput) ? "email" : "name";

    if (searchInput) {
        mQuery = query(cl,
            where(searchField, '>=', searchInput),
            where(searchField, '<', searchInput + '\uf8ff'),
            // orderBy(sortColumn, sortOrder),
            limit(usersPerPage)
        );
    }

    const snapshot = await getDocs(mQuery);
    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });
    console.log('users = ', rows)
    return { snapshot, rows };

}

export const fetchCustomerInfo = async (customerId) => {
    const cl = doc(db, 'users', customerId)
    try {
        const docSnap = await getDoc(cl);
        if (docSnap.exists()) {
            return docSnap.data()
        } else {
            return null;
        }
    } catch (err) {
        console.log('fetchCustomerInfo : ', err)
        return null
    }
    // if (docSnap.exists()) {
    //     return docSnap.data()
    // } else {
    //     console.log('Error fetch customer info')
    //     return null;
    // }
}

export const fetchOrderById = async (orderId) => {
    const ordersCollection = doc(db, 'orders', orderId);
    try {
        const docSnap = await getDoc(ordersCollection);
        if (docSnap.exists()) {
            const orderData = docSnap.data()
            console.log('orderData.customerModel.uid = ', orderData.customerModel.uid)
            const customerData = await fetchCustomerInfo(orderData.customerModel.uid)
            return {
                ...orderData,
                customer: customerData,
                id: orderId
            }
        } else {
            console.log("No such document!");
            return null
        }
    } catch (err) {
        console.log('fetchOrderById err: ', err)
    }
}


export const fetchTransactions = async (currentPage, lastVisibleStack, rowsPerPage) => {
    const cl = collection(db, 'transactions');
    let mQuery;
    if (currentPage === 1) {
        // Fetch the first page
        mQuery = query(cl, orderBy('created', 'desc'), limit(rowsPerPage));
    } else if (currentPage > lastVisibleStack.length) {
        // Fetch the next page after the last visible document of the previous page
        const lastVisible = lastVisibleStack[lastVisibleStack.length - 1];
        mQuery = query(cl, orderBy('created', 'desc'), startAfter(lastVisible), limit(rowsPerPage));
    } else {
        // Fetch the previous page using the document at the start of the previous page
        const prevLastVisible = lastVisibleStack[currentPage - 2];
        mQuery = query(cl, orderBy('created', 'desc'), startAt(prevLastVisible), limit(rowsPerPage));
    }

    const snapshot = await getDocs(mQuery);

    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });

    const uids = rows.map(row => row.uid).filter(uid => uid !== undefined); // Filtering out undefined values if any
    const users = await fetchUsersByIds(uids)
    console.log('users = ', users)

    const rowsMapped = rows.map(row => {
        const rowUser = users.find(u => u.id === row.uid)
        return {
            ...row,
            userInfo: rowUser
        }
    })

    console.log('rows = ', rowsMapped)
    return { snapshot, rows: rowsMapped };

}

export const fetchUsersByIds = async (userIds) => {
    if (!userIds || userIds.length === 0) {
        return []; // Return an empty array if no user IDs are provided
    }

    try {
        // Create a query to get users whose document IDs are in the userIds array
        const usersCollection = collection(db, 'users');
        const usersQuery = query(usersCollection, where('__name__', 'in', userIds));

        // Execute the query
        const querySnapshot = await getDocs(usersQuery);

        // Map through the query results and extract user data
        const users = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        console.log('Fetched Users:', users);
        return users;
    } catch (error) {
        console.error("Error fetching users by IDs:", error);
        return [];
    }
};

export const fetchUserById = async (userId) => {
    if (!userId || userId.length === 0) {
        return null;
    }
    const userRef = doc(db, 'users', userId);
    try {
        const userSnap = await getDoc(userRef);
        if (userSnap.exists) {
            // Map through the query results and extract user data

            // 2. Get order references
            const orderReferences = userSnap.data().orders || []; // Assuming 'orders' is an array of DocumentReferences
            // Get the last 10 orders (if the length is less than 10, get all of them)
            const lastOrderRefs = orderReferences.reverse();
            // 3. Fetch the last three orders
            const orderPromises = lastOrderRefs.map((orderRef) => getDoc(orderRef));
            const orderSnapshots = await Promise.all(orderPromises);
            // Convert snapshots to data
            const orders = orderSnapshots.map((orderSnap) => ({
                ...orderSnap.data(),
                id: orderSnap.id,
            }));


            //transactions
            const transactionReferences = userSnap.data().transactions || [];
            const lastTransactionRefs = transactionReferences.reverse();
            const transactionsPromise = lastTransactionRefs.map(transaction => getDoc(doc(db, 'transactions', transaction)))
            const transactionsSnapshot = await Promise.all(transactionsPromise);
            const transactions = transactionsSnapshot.map(transactionSnap => ({
                ...transactionSnap.data(),
                id: transactionSnap.id,
            }))

            const user = {
                ...userSnap.data(),
                id: userId,
                recentOrders: orders,
                recentTransactions: transactions,
            }


            console.log('Fetched User:', user);
            return user;
        } else {

            console.log("No such document!");
            return null
        }

    } catch (error) {
        console.error("Error fetching user by ID :", error);
        return null;
    }
};

export const fetchOrderByStore = async (selectedStoreAddress, currentPage, lastVisibleStack, rowsPerPage = 10, sortColumn, sortOrder) => {
    const ordersCollection = collection(db, 'orders');
    let orderQuery;

    if (currentPage === 1) {
        // Fetch the first page
        if (selectedStoreAddress === 'all') {
            orderQuery = query(ordersCollection,
                orderBy(sortColumn, sortOrder),
                // orderBy('createdAt', 'desc'),
                limit(rowsPerPage));
        } else {
            orderQuery = query(ordersCollection,
                where("cart.shopAddress", "==", selectedStoreAddress),
                orderBy(sortColumn, sortOrder),
                // orderBy('createdAt', 'desc'),
                limit(rowsPerPage));
        }
    } else if (currentPage > lastVisibleStack?.length) {
        // Fetch the next page after the last visible document of the previous page
        const lastVisible = lastVisibleStack[lastVisibleStack?.length - 1];
        if (selectedStoreAddress === 'all') {
            orderQuery = query(ordersCollection,
                orderBy(sortColumn, sortOrder),
                // orderBy('createdAt', 'desc'),
                startAfter(lastVisible),
                limit(rowsPerPage));
        } else {
            orderQuery = query(ordersCollection,
                where("cart.shopAddress", "==", selectedStoreAddress),
                orderBy(sortColumn, sortOrder),
                // orderBy('createdAt', 'desc'),
                startAfter(lastVisible),
                limit(rowsPerPage));
        }
    } else {
        // Fetch the previous page using the document at the start of the previous page
        const prevLastVisible = lastVisibleStack[currentPage - 2];
        if (selectedStoreAddress === 'all') {
            orderQuery = query(ordersCollection,
                orderBy('createdAt', 'desc'),
                startAt(prevLastVisible),
                limit(rowsPerPage));
        } else {
            orderQuery = query(ordersCollection,
                where("cart.shopAddress", "==", selectedStoreAddress),
                orderBy('createdAt', 'desc'),
                startAt(prevLastVisible),
                limit(rowsPerPage));
        }
    }

    console.log('bro address = ', selectedStoreAddress)
    const snapshot = await getDocs(orderQuery);
    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });
    console.log('orderList = ', rows)
    return { snapshot, rows };
}

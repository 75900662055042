import React, { useEffect } from 'react';
import { useNavigate, Route, Routes, Navigate } from 'react-router-dom';
import { setNavigate } from "./utils/utils";
import Login from './page/Auth/Login';
import NotFound from './page/NotFound';
import DishManage from 'page/DishManage';
import OrderManage from 'page/order/OrderManage';
import OrderDetails from 'page/order/OrderDetails';
import UserManage from 'page/User/UserManage';
import PrivateRoute from 'component/PrivateRoute';
import TransactionManage from 'page/Transaction/TransactionManage';
import OrderHistoryManage from 'page/order_history/OrderHistoryManage';
import UserDetail from 'page/User/UserDetails';
import RatingManage from 'page/Rating/RatingManage';

const publicRoutes = [
    { path: "/", element: <Navigate replace to="/login" /> },
    { path: "/login", element: <Login /> },
    // { path: "/dashboard", element: <BuyerDashboard /> },
    // { path: "/products/:id", element: <ProductDashboard /> },
];

const superAdminRoutes = [
    { path: "/admin/dish", element: <DishManage /> },
    { path: "/admin/user", element: <UserManage /> },
    { path: "/admin/user/:id", element: <UserDetail /> },
    { path: "/admin/transaction", element: <TransactionManage /> },
    { path: "/admin/order-history", element: <OrderHistoryManage /> },
    { path: "/admin/order-history/:id", element: <OrderDetails /> },
    { path: "/admin/rating", element: <RatingManage /> },
]

const adminRoutes = [
    { path: "/admin", element: <Navigate replace to="/admin/order" /> },
    { path: "/admin/order", element: <OrderManage /> },
    { path: "/admin/order/:id", element: <OrderDetails /> },
    // { path: "/admin/manage-products/:id/ratings", element: <ProductRatings /> },
]

const AppRoutes = () => {
    const navigate = useNavigate();
    useEffect(() => {
        setNavigate(navigate);
    }, [navigate]);

    return (
        <Routes>

            {publicRoutes.map(({ path, element }) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        element
                    }
                />
            ))}

            {/* Protected Routes */}
            {adminRoutes.map(({ path, element }) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        <PrivateRoute>{element}</PrivateRoute>
                    }
                />
            ))}

            {/* Protected Routes */}
            {superAdminRoutes.map(({ path, element }) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        <PrivateRoute isSuper={true}>{element}</PrivateRoute>
                    }
                />
            ))}

            {/* Catch-All Route */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;

import Sidebar from "component/Sidebar"
import Header from "component/Header"
import TransactionTable from "./TransactionTable"

const TransactionManage = () => {
    return (
        <>
            <Header root={true} />
            <div className="h-screen bg-gray-100 flex">
                <Sidebar />
                <div className="flex-1 p-8">
                    <div className="w-full h-full bg-gray-200 border-dashed border-4 border-gray-300">
                        <TransactionTable />
                    </div>
                </div>
            </div>
        </>)
}

export default TransactionManage
import Header from "component/Header";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { fetchOrderById, updateOrderStatus } from "services/adminService";
import OrderStatus from "./OrderStatus";

const OrderDetails = () => {
  const [order, setOrder] = useState()
  const param = useParams();
  const navigate = useNavigate()
  const { user } = useAuth()
  const images = require.context('assets/temp', false, /\.(png|webp)$/);

  useEffect(() => {
    fetchData()

  }, [param.id])

  const fetchData = async () => {
    await fetchOrderById(param.id).then(res => {
      if (res) {
        setOrder(res)
      } else {
        console.log('Error')
        navigate('/admin/order')
      }
    }).catch(err => {
      console.log('Error: as', err)
      navigate('/admin/order')
    })
  }

  const getImagePath = (imageName) => {
    const webp = imageName.replace(/\.[^.]+$/, '.webp');
    try {
      return images(`${webp}`);
    } catch (err) {
      console.error('Image not found:', webp);
      return null;
    }
  };

  const getOptionsString = (options) => {
    var result = [];
    if (options && Object.keys(options).length > 0) {
      for (const [key, value] of Object.entries(options)) {
        result.push(`${key}: ${value}`)
      }
      return result
    }
  }

  const onChangeOrderStatus = async (newStatus) => {
    console.log(`Order ID: ${order.id}, New Status: ${newStatus}`);

    try {
      // Call service to update order status (this function should be defined in your service file)
      await updateOrderStatus(order, newStatus);
      await fetchData()
      // No need to manually setOrders here since onSnapshot will update automatically

    } catch (error) {
      console.error("Error updating order status:", error);
    }
  }

  // Add custom options details
  // if (customOptions != null && customOptions.isNotEmpty) {
  //   if (result.isNotEmpty) result += '\n'; // Separate sections if both exist
  //   result += 'Custom Options:\n';
  //   customOptions.forEach((key, value) {
  //     result += '- $key: $value\n';
  //   });
  // }

  return (<>
    <Header />
    {order && <div className="flex flex-col bg-gray-50 p-6 rounded-lg shadow-lg">
      <div className="p-4 bg-white rounded-md">
        {/* {user?.isSuper && <h3 className="text-gray-800 font-semibold mb-2">User ID: {order.customerModel?.uid}</h3>} */}
        <p className="text-orange-500 font-medium">Name: {order.customerModel?.name}</p>
        <p className="text-gray-600">Email: {order.customer?.email}</p>
        {/* {user?.isSuper && <p className="text-gray-600">Balance: ${order.customer?.balance / 100}</p>} */}
        {user?.isSuper && <button
          onClick={() => { navigate(`/admin/user/${order.customerModel.uid}`) }}
          className="mt-5 text-gray-700 font-semibold border border-gray-300 px-4 py-2 rounded-md focus:outline-none">
          View Detail
        </button>}
      </div>

      <div className="flex">
        <div className="w-1/3 mt-5 bg-white">
          <OrderStatus currentStatus={order.orderStatus} onChangeOrderStatus={onChangeOrderStatus} />
        </div>
        <div className="w-2/3 mt-5 ml-4 p-4 bg-white rounded-md">
          <h3 className="text-gray-800 font-semibold mb-2">Order ID: #{order.id.substring(0, 5)}</h3>
          <p className="text-gray-600 mb-4">{new Date(order?.createdAt).toLocaleString()}</p>
          {order?.cart?.orderLines?.map((item, index) => (
            <div key={index} className="flex items-center mb-4">
              <img src={getImagePath(item.imageUrl)} alt={item.dishName} className="w-16 h-16 rounded-md mr-4" />
              <div>
                <p className="text-gray-800 font-semibold">{item.itemCount}x {item.dishName}</p>
                <p className="text-gray-600 text-sm">{item.dishSize}</p>
                {item.requireOptions && getOptionsString(item.requireOptions)?.map((rOption, index) =>
                  <p key={index} className="text-gray-600 text-sm">{rOption}</p>
                )}
                {item.customOptions && getOptionsString(item.customOptions)?.map((cOption, index) =>
                  <p key={index} className="text-gray-600 text-sm">{cOption}</p>
                )}
                <p className="text-gray-800 font-medium">${item.price.toFixed(2)}</p>
              </div>
            </div>
          ))}
          <div className="mt-4">
            <h4 className="text-gray-800 font-semibold">Store Address</h4>
            <p className="text-gray-600 mb-4">{order.cart.shopAddress}</p>
            <h4 className="text-gray-800 font-semibold">Payment</h4>
            <button className="w-full bg-orange-500 text-white py-2 rounded-md font-semibold mt-2">APP WALLET</button>
            <h4 className="text-gray-800 font-semibold mt-4">Order Summary</h4>
            <div className="flex justify-between text-gray-800 mt-2">
              <span>Sub total</span>
              <span>${order.cart.subTotalAmount?.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-gray-800">
              <span>TAX</span>
              <span>${order.cart.vatAmount?.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-gray-800 mt-4 border-t pt-4 font-semibold">
              <span>Total</span>
              <span>${order.cart.totalAmount?.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>}
  </>
  );
}
export default OrderDetails
import Header from "component/Header"
import Sidebar from "component/Sidebar"
import RatingTable from "./RatingTable"

const RatingManage = () => {

    return (<>
        <Header root={true} />
        <div className="h-screen bg-gray-100 flex">
            <Sidebar />
            <div className="flex-1 p-8">
                <div className="w-full h-full bg-gray-200 border-dashed border-4 border-gray-300">
                    <RatingTable />
                </div>
            </div>
        </div>
    </>
    )
}

export default RatingManage
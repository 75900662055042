import Sidebar from "component/Sidebar"
import TopBar from "component/Topbar"

const DishManage = () => (
    <>
        <div className="h-screen bg-gray-100 flex">
            <Sidebar />
            <div className="flex-1 p-8">
                <TopBar />
                <div className="w-full h-full bg-gray-200 border-dashed border-4 border-gray-300"></div>
            </div>
        </div>
    </>
)

export default DishManage
import AuthContext from "context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router";

const TopBar = () => {
    const { logout } = useContext(AuthContext)
    const navigate = useNavigate()

    const handleLogout = async () => {
        await logout();
        navigate('/login')
    }
    return (
        <div className="flex items-center justify-between mb-8">
            <div className="relative w-full max-w-xs">
                <input type="search" placeholder="Search..." className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300" />
                <div className="absolute top-0 left-0 flex items-center h-full ml-3">
                    <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4v16m8-16v16M3 8h18" />
                    </svg>
                </div>
            </div>
            <div className="flex items-center">
                <button className="p-2 rounded-full hover:bg-gray-200"
                    onClick={handleLogout}
                >
                    <svg className="w-6 h-6 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m-6-3h18m-6 9h-1" />
                    </svg>
                </button>
                <img src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&q=80" alt="User" className="w-10 h-10 rounded-full ml-4" />
            </div>
        </div>
    );
}
export default TopBar
import React, { useState } from 'react';

const UserSearchInput = ({ onSearch }) => {
    const [searchInput, setSearchInput] = useState('');

    // Handle input change
    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    // Handle the clear input action
    const handleClearInput = () => {
        setSearchInput("");
        onSearch(""); // Trigger the search with an empty string to reset
    };

    // Handle key press for Enter key
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearch(searchInput);
        }
    };

    return (<>
        <input
            type="text"
            value={searchInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}

            placeholder="Search user or email (case-sensitive)"
            className="w-full pl-4 pr-10 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
        />
        {searchInput && <button
            onClick={handleClearInput}
            className="absolute top-1/2 transform -translate-y-1/2 right-3 text-gray-500 hover:text-gray-700">
            &times;
        </button>}
    </>
    );
};

export default UserSearchInput;

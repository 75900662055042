import Sidebar from "component/Sidebar"
import UserTable from "./UserTable"
import Header from "component/Header"


const UserManage = () => (
    <>
        <Header root={true} />
        <div className="h-screen bg-gray-100 flex">
            <Sidebar />
            <div className="flex-1 p-8">
                <div className="w-full h-full bg-gray-200 border-dashed border-4 border-gray-300">
                    <UserTable />
                </div>
            </div>
        </div>
    </>
)

export default UserManage